import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import * as DATA from "@/game-tft/set-nine-half-constants.mjs";
import { Layout } from "@/game-tft/UpdatesSetNineHalf.style.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import Banner from "@/shared/SplashBanner.jsx";

/* eslint-disable i18next/no-literal-string */

function Updates() {
  const { t } = useTranslation();

  return (
    <Layout>
      <PageHeader />
      <Banner
        image={DATA.HEADER_IMAGE}
        title={t(...DATA.HEADER_TITLE)}
        title2={DATA.HEADER_TITLE2}
        style={{ marginTop: "0" }}
      />

      <div className="first-section">
        <h3 className="type-h4">{t("val:whatsNew", "What's new?")}</h3>
        <Card>
          <div className="card-contents whats-new">
            <p className="type-body1">
              Here is a sneak peek into TFT Horizonbound mid-set update! <br />
              This update will be available on the Public Beta Environment (PBE)
              on Tuesday, August 29. <br />
              The official release is on Wednesday, September 13 with Patch
              13.18.
            </p>
            <p className="type-body1">
              Runeterra Reforged: Horizonbound brings players to the edges of
              Runeterra, with destinations in the dangerous smuggler haven
              Bilgewater and the reclusive jungle lands of Ixtal. Champions from
              both regions will be joining the action in Horizonbound.. Players
              can battle it out for superiority in four new nautically themed
              arenas while reacting to ever-changing team dynamics and portals
              that change the core rules in surprising new ways.
            </p>
            <p>
              The new set update will feature the following:
              <ul>
                <li>New Units - including Milio, Silco, and Naafiri!</li>
                <li>New Items</li>
                <li>New Traits</li>
                <li>New Augments</li>
                <li>New Region Portals</li>
              </ul>
            </p>
          </div>
        </Card>
      </div>

      <div>
        <h3 className="type-h4">{t("tft:newTraits", "New Traits")}</h3>
        <Card>
          <ul className="new-traits list card-contents">
            {DATA.NEW_TRAITS.map((trait) => (
              <ListItem
                key={trait.name}
                videoHero={trait.videoHero}
                meta={trait.meta}
              />
            ))}
          </ul>
        </Card>
      </div>

      <div>
        <h3 className="type-h4">{t("tft:newUnits", "New Units")}</h3>
        <Card>
          <ul className="new-units list card-contents">
            {DATA.NEW_UNITS.map((unit) => (
              <ListItem
                key={unit.name}
                videoHero={unit.videoHero}
                imageLazy={true}
                meta={unit.meta}
              />
            ))}
          </ul>
        </Card>
      </div>

      <div>
        <h3 className="type-h4">{t("tft:newPortals", "New Portals")}</h3>
        <Card>
          <ul className="new-traits list card-contents">
            {DATA.NEW_PORTALS.map((portal) => (
              <ListItem
                key={portal.name}
                imageHero={portal.imageHero}
                imageLazy={true}
                meta={portal.meta}
              />
            ))}
          </ul>
        </Card>
      </div>

      <div>
        <h3 className="type-h4">{t("tft:newItemTypes", "New Item Types")}</h3>
        <Card>
          <ul className="new-items list card-contents">
            {DATA.NEW_ITEMS.map((item, i) => (
              <ListItem
                key={item.name}
                reverse={(i + 1) % 2 === 0 && true}
                imageTransparent={true}
                imageHero={item.imageHero}
                imageHeroSize={item.imageHeroSize}
                imageLazy={true}
                meta={item.meta}
              />
            ))}
          </ul>
        </Card>
      </div>

      <section className="disclaimer">
        <span className="type-body1-form--active">
          Page is based on data Riot provided early.
        </span>
        <span className="type-body1-form" style={{ color: "var(--shade1)" }}>
          Page will automatically update once more info gets revealed.
        </span>
      </section>
    </Layout>
  );
}

function ListItem({
  reverse,
  imageTransparent,
  videoHero,
  imageHero,
  imageHeroSize,
  imageLazy,
  meta,
  poster,
}) {
  return (
    <li className="trait list-item-container">
      <div
        className="list-item"
        data-reverse={Boolean(reverse)}
        data-transparent={Boolean(imageTransparent)}
        data-custom-size={Boolean(imageHeroSize?.width)}
        style={{
          "--ar":
            imageHeroSize?.width && imageHeroSize.width / imageHeroSize.height,
        }}
      >
        {videoHero ? (
          <video
            className="hero-video"
            controls
            controlsList="nodownload noplaybackrate"
            width="412"
            height="232"
            poster={poster}
          >
            <source src={videoHero} type="video/mp4" />
          </video>
        ) : imageHero ? (
          <img
            src={imageHero}
            className="hero-image"
            width={imageHeroSize?.width ?? "412"}
            height={imageHeroSize?.height ?? "232"}
            alt={meta?.[0]?.name}
            loading={imageLazy ? "lazy" : "eager"}
          />
        ) : null}
        {meta?.length ? (
          <div className="meta-blocks">
            {meta.map((info) => (
              <div key={info.name} className="meta">
                <div className="name-info">
                  {info.nameImages?.length ? (
                    <div className="name-images">
                      {info.nameImages.map((img) => (
                        <img
                          key={img}
                          src={img}
                          width="44"
                          height="44"
                          alt={img}
                          loading={imageLazy ? "lazy" : "eager"}
                        />
                      ))}
                    </div>
                  ) : null}
                  <h5 className="name type-h6">{info.name}</h5>
                </div>
                <p className="description type-body1">{info.description}</p>
                {info?.list?.length ? (
                  <ol className="units type-body1">
                    {info.list.map((unit) => (
                      <li key={unit} className="unit">
                        {unit}
                      </li>
                    ))}
                  </ol>
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </li>
  );
}

export default Updates;

// TODO: meta dat
export function meta() {
  return {
    title: [
      "tft:helmet.updates.titleSet95",
      "TFT Set 9.5 Horizonbound Update – Blitz Teamfight Tactics",
    ],
    description: [
      "tft:helmet.updates.descriptionSet95",
      "TFT Set 9.5 Horizonbound Update is Here! Check out all new champions, traits, items and systems.",
    ],
    image: {
      url: DATA.HEADER_IMAGE,
      alt: [
        "tft:helmet.updates.titleSet95",
        "TFT Set 9.5 Horizonbound Update – Blitz Teamfight Tactics",
      ],
      width: 984,
      height: 555,
    },
  };
}
