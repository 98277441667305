import { appURLs } from "@/app/constants.mjs";

export const HEADER_IMAGE = `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/set-nine-half-hero.webp`;

export const HEADER_TITLE = ["", "Set 9.5 Update - Horizonbound"];

export const HEADER_TITLE2 = ["", "Teamfight Tactics -  Aug 2023"];

export const WHATS_NEW_PARAGRAPHS = [
  [
    "",
    "Here is a sneak peek into TFT Horizonbound mid-set update!The update will be available on the Public Beta Environment (PBE) on Tuesday, August 29 and will be released to all players on September 12, 2023.",
  ],
  [
    "",
    "Runeterra Reforged: Horizonbound brings players to the edges of Runeterra, with destinations in the dangerous smuggler haven Bilgewater and the reclusive jungle lands of Ixtal. Champions from both regions will be joining the action in Horizonbound.. Players can battle it out for superiority in four new nautically themed arenas while reacting to ever-changing team dynamics and portals that change the core rules in surprising new ways.",
  ],
];

export const NEW_TRAITS = [
  {
    videoHero: `${appURLs.CDN_VIDEOS}/tft/SetUpdatePage/set9_5/TFT9.5_GameplayOverview_Bilgewater.mp4`,
    poster: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/TFT9.5_GameplayOverview_Bilgewater.png`,
    meta: [
      {
        name: "Bilgewater",
        description:
          "Fire the cannons! Bilgewater (3/5/7/9) locals mark their foes for periodic barrages from their waterborne artillery!",
        list: [
          "Graves",
          "Illaoi",
          "Twisted Fate",
          "Miss Fortune",
          "Nautilus",
          "Nilah",
          "Gangplank",
        ],
      },
    ],
  },
  {
    videoHero: `${appURLs.CDN_VIDEOS}/tft/SetUpdatePage/set9_5/TFT9.5_GameplayOverview_IxtalElectricV1.mp4`,
    poster: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/TFT9.5_GameplayOverview_IxtalElectricV1.png`,
    meta: [
      {
        name: "Ixtal",
        description:
          "Each game, Ixtal units create different elemental hexes that empower whoever's on them, and empower Ixtal units even more!",
        list: [
          "Milio",
          "Qiyana",
          "Rengar",
          "Malphite",
          "Nidalee",
          "Zyra",
          "Neeko",
        ],
      },
    ],
  },
  {
    videoHero: `${appURLs.CDN_VIDEOS}/tft/SetUpdatePage/set9_5/TFT9.5_GameplayOverview_VanquishersV2.mp4`,
    poster: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/TFT9.5_GameplayOverview_VanquishersV2.png`,
    meta: [
      {
        name: "Vanquisher",
        description:
          "Damage from Vanquisher Abilities can critically strike. Vanquishers gain bonus Critical Strike Chance and Damage (2/4/6).",
        list: ["Ashe", "Nilah", "Xayah", "Darius", "Jhin"],
      },
    ],
  },
];

function nameImage(key) {
  return `${appURLs.CDN}/blitz/tft/champion_squares/set1/${key}.png`;
}

export const NEW_UNITS = [
  {
    videoHero: `${appURLs.CDN_VIDEOS}/tft/SetUpdatePage/set9_5/TFT9.5_GameplayOverview_Naafiri.mp4`,
    poster: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/TFT9.5_GameplayOverview_Naafiri.png`,
    meta: [
      {
        name: "Naafiri",
        nameImages: [nameImage("Naafiri")],
        description:
          "A single target damage carry, Naafiri can grant her Darkin Passive to allies upon falling in battle, calling packmates to attack the user's target.",
      },
    ],
  },
  {
    videoHero: `${appURLs.CDN_VIDEOS}/tft/SetUpdatePage/set9_5/TFT9.5_GameplayOverview_GangplankMelee.mp4`,
    poster: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/TFT9.5_GameplayOverview_GangplankMelee.png`,
    meta: [
      {
        name: "Gangplank",
        nameImages: [nameImage("Gangplank")],
        description:
          "Call the Dreadway, distributing citrus to allies, and exploding into enemies. Use his cutlass or his pistol to ignite your foes or cast faster!",
      },
    ],
  },
  {
    videoHero: `${appURLs.CDN_VIDEOS}/tft/SetUpdatePage/set9_5/TFT9.5_GameplayOverview_Silco.mp4`,
    poster: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/TFT9.5_GameplayOverview_Silco.png`,
    meta: [
      {
        name: "Silco",
        nameImages: [nameImage("Silco")],
        description:
          "Silco slings Shimmer at a foe, damaging them and enemies nearby. Allies in the contamination zone are healed.",
      },
    ],
  },
  {
    videoHero: `${appURLs.CDN_VIDEOS}/tft/SetUpdatePage/set9_5/TFT9.5_GameplayOverview_Fiora.mp4`,
    poster: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/TFT9.5_GameplayOverview_Fiora.png`,
    meta: [
      {
        name: "Fiora",
        nameImages: [nameImage("Fiora")],
        description:
          "Waltz through battle with Fiora, a Challenger who strikes her enemies while untargetable and heals for a portion of the damage dealt.",
      },
    ],
  },
  {
    videoHero: `${appURLs.CDN_VIDEOS}/tft/SetUpdatePage/set9_5/TFT9.5_GameplayOverview_MordekaiserV3.mp4`,
    poster: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/TFT9.5_GameplayOverview_MordekaiserV3.png`,
    meta: [
      {
        name: "Mordekaiser",
        nameImages: [nameImage("Mordekaiser")],
        description:
          "Mordekaiser hits slow, but hard. The hits get harder as he slays foes.",
      },
    ],
  },
  {
    videoHero: `${appURLs.CDN_VIDEOS}/tft/SetUpdatePage/set9_5/TFT9.5_GameplayOverview_XayahNilah.mp4`,
    poster: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/TFT9.5_GameplayOverview_XayahNilah.png`,
    meta: [
      {
        name: "Xayah & Nilah",
        nameImages: [nameImage("Xayah"), nameImage("Nilah")],
        description: `Shred your foes' armor with razor-sharp feathers.
          Xayah has a new class, Vanquisher, which increases
          critical strike chance against tankier enemies.
          
          Nilah's attacks strike in an AOE.
          She dives the enemy donning a
          shield, and gaining attack speed.`,
      },
    ],
  },
  {
    videoHero: `${appURLs.CDN_VIDEOS}/tft/SetUpdatePage/set9_5/TFT9.5_GameplayOverview_Milio.mp4`,
    poster: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/TFT9.5_GameplayOverview_Milio.png`,
    meta: [
      {
        name: "Milio",
        nameImages: [nameImage("Milio")],
        description:
          "Milio’s Ultra Mega Fire Kick launches a fiery ball that stuns the first enemy hit, then bounces to the closest enemy behind them.",
      },
    ],
  },
  {
    videoHero: `${appURLs.CDN_VIDEOS}/tft/SetUpdatePage/set9_5/TFT9.5_GameplayOverview_MissFortune.mp4`,
    poster: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/TFT9.5_GameplayOverview_MissFortune.png`,
    meta: [
      {
        name: "Miss Fortune",
        nameImages: [nameImage("MissFortune")],
        description:
          "Miss Fortune calls down an X-shaped rain of bullets, dealing damage and wounding her foes.",
      },
      {
        name: "Nautilus",
        nameImages: [nameImage("Nautilus")],
        description:
          "Nautilus summons a whirlpool pulling unlucky swimmers (or sinkers?) together and knocking them up.",
      },
      {
        name: "Graves & Illaoi",
        nameImages: [nameImage("Graves"), nameImage("Illaoi")],
        description:
          "Graves' modified Smoke Grenade chills enemies who enter the smoke. Illaoi teaches Harsh Lessons, healing from damage taken by her target.",
      },
    ],
  },
];

export const NEW_PORTALS = [
  {
    imageHero: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/portal-ixtal.webp`,
    meta: [
      {
        name: "Ixtal - Ixaocan",
        description:
          "Take a portal to Ixaocan, where starring up a set number of units means all players will gain increasingly valuable loot.",
      },
    ],
  },
  {
    imageHero: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/portal-bilgewater.webp`,
    meta: [
      {
        name: "Bilgewater - Rat Town",
        description:
          "Lucky Shops can appear randomly, featuring units tailored to your army.",
      },
    ],
  },
];

export const NEW_ITEMS = [
  {
    imageHero: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/core-items.webp`,
    imageHeroSize: {
      width: 500,
      height: 356,
    },
    meta: [
      {
        name: "Core Items",
        description: "Items built from components. There are 6 new core items!",
      },
    ],
  },
  {
    imageHero: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/artifacts2.webp`,
    imageHeroSize: {
      width: 500,
      height: 216,
    },
    meta: [
      {
        name: "Artifacts",
        description:
          "Unique items with interesting outputs that also always make the wearer stronger. They have a red border. Some Shimmerscale items are now Artifacts!",
      },
    ],
  },
  {
    imageHero: `${appURLs.CDN}/blitz/tft/set-update-page/set9_5/support-items.webp`,
    imageHeroSize: {
      width: 480,
      height: 247,
    },
    meta: [
      {
        name: "Support Items",
        description:
          "Items that powers up the team in a unique way. They have a new silver border. ",
      },
    ],
  },
];
