import { styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import Container from "@/shared/ContentContainer.jsx";

export const Layout = styled(Container)`
  display: grid;
  grid-auto-rows: auto;
  gap: 3.25rem;

  header {
    display: none;
  }

  ${mobile} {
    .first-section {
      margin-top: var(--sp-30);
    }
  }

  .whats-new {
    padding: var(--sp-10);
    color: var(--shade1);

    p,
    span {
      max-width: 80ch;
    }
    p:not(:last-child) {
      margin-block-end: 2rem;
    }
    ul {
      list-style-type: disc;
      padding-left: var(--sp-8);
    }
  }

  .list {
    --padd: 2.5rem;
    padding: 0 var(--padd);

    @container content-card (inline-size <= 500px) {
      --padd: 1.5rem;
    }
  }
  .list-item-container {
    container-type: inline-size;
    container-name: list-item;

    &:not(:last-child) {
      .list-item {
        border-bottom: 1px solid var(--shade6);
      }
    }
  }
  .list-item {
    display: flex;
    align-items: center;
    gap: 3rem;
    padding: var(--padd) 0;

    @container list-item (inline-size <= 650px) {
      display: grid;
      gap: 1rem;
    }

    &[data-transparent="true"] {
      .hero-image,
      .hero-video {
        background: transparent;
        box-shadow: none;
      }
    }
    &[data-custom-size="true"] {
      .hero-image,
      .hero-video {
        aspect-ratio: var(--ar);
      }
    }
    &[data-reverse="true"] {
      .meta-blocks {
        order: -1;

        @container list-item (inline-size <= 650px) {
          order: unset;
        }
      }
    }

    .hero-image,
    .hero-video {
      max-width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
      flex: 0 0 424px;
      background: var(--shade9);
      border-radius: var(--br);
      box-shadow: 0 0 0 var(--sp-1_5) var(--shade9);

      @container list-item (inline-size <= 650px) {
        width: 100%;
      }
    }

    .meta-blocks {
      display: grid;
      grid-auto-column: auto;
      gap: var(--sp-5);
    }
    .meta > *:not(:last-child) {
      margin-block-end: var(--sp-2);
    }
    .name-info {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    .name-images {
      display: flex;
      align-items: center;

      img {
        aspect-ratio: 1;
        object-view-box: inset(10% 10% 10% 10%);
        border-radius: 50%;
        box-shadow: 0 0 0 4px var(--shade9);

        &:not(:first-child) {
          margin-left: -0.5rem;
        }
      }
    }
    .description,
    .units {
      color: var(--shade1);
    }
    .description {
      max-width: 34ch;
    }
  }

  .new-traits {
    .units {
      list-style: disc;
      padding-inline-start: 2rem;
    }
  }

  .disclaimer {
    text-align: center;

    > * {
      display: block;
    }
  }
`;
